import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import logo from "../img/lonely-lentil-logo-2021.svg";
// import logo from "../img/logo-xmas-2021.svg";

import Terms from "../components/Terms";
import Privacy from "../components/Privacy";

const Navbar = ({}) => {
  const [termsModalActive, setTermsModal] = useState(false);
  const [privacyModalActive, setPrivacyModal] = useState(false);

  useEffect(() => {
    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll(".navbar-burger"),
      0
    );
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
      // Add a click event on each of them
      $navbarBurgers.forEach((el) => {
        el.addEventListener("click", () => {
          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          // Toggle the "is-active" className on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle("is-active");
          $target.classList.toggle("is-active");
        });
      });
    }
  });

  return (
    <>
      <Link
        to="/"
        className="full-width-logo is-hidden-touch"
        style={{ marginTop: "30px", marginBottom: "30px" }}
      >
        <img
          src={logo}
          alt="Lonely Lentil"
          style={{ height: "100%", marginTop: "-50px", marginBottom: "-30px" }}
        />
        {/* <img
          src={logo}
          alt="Lonely Lentil"
          style={{ height: "150%", marginTop: "-50px", marginBottom: "-30px" }}
        /> */}
      </Link>

      <nav
        className="navbar is-light is-size-6 "
        role="navigation"
        aria-label="main-navigation"
      >
        <div id="navContainer" className="container">
          <div className="navbar-brand is-hidden-desktop">
            <Link to="/" className="navbar-item " title="Logo">
              <img src={logo} alt="Lonely Lentil" />
            </Link>

            <a
              role="button"
              className="navbar-burger burger"
              aria-expanded="false"
              data-target="navMenu"
            >
              <span />
              <span />
              <span />
            </a>
          </div>
          <div id="navMenu" className="navbar-menu has-text-centered">
            <div
              className="navbar-start has-text-centered"
              style={{ flexGrow: 1, justifyContent: "center" }}
            >
              <Link className="navbar-item" to="/">
                <i className="fas fa-home"></i>
              </Link>
              <Link className="navbar-item" to="/products">
                Products
              </Link>
              <Link className="navbar-item" to="/delivery">
                Delivery Information
              </Link>
              <Link className="navbar-item" to="/collaborations">
                Collaborations
              </Link>
              <Link className="navbar-item" to="/about">
                About Us
              </Link>

              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link">More</a>

                <div className="navbar-dropdown">
                  <a
                    className="navbar-item"
                    onClick={() => setTermsModal(true)}
                  >
                    Terms & Conditions
                  </a>
                  <a
                    className="navbar-item"
                    onClick={() => setPrivacyModal(true)}
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>

              <a
                className="navbar-item"
                target="_blank"
                href="https://www.facebook.com/lonelylentil/"
              >
                <i className="fab fa-facebook-square"></i>
              </a>
              <a
                className="navbar-item"
                target="_blank"
                href="https://www.instagram.com/lonelylentil/"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                className="navbar-item"
                target="_blank"
                href="mailto:info@lonelylentil.co.uk?Subject=Hello!"
              >
                <i className="far fa-envelope"></i>
              </a>
            </div>
            <div className="navbar-end has-text-centered"></div>
          </div>
        </div>
      </nav>

      <Terms isActive={termsModalActive} onClose={() => setTermsModal(false)} />
      <Privacy
        isActive={privacyModalActive}
        onClose={() => setPrivacyModal(false)}
      />
    </>
  );
};
export default Navbar;
