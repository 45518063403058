import React from 'react'


const Footer = class extends React.Component {
  render() {
    return (
      <>
      <footer className="footer footer-stroke is-hidden-mobile">
        <div className="content  has-text-centered " >
          <div className="container  ">
          <h1
            className=" tile-title "
            style={{
                color: "white"
            }}>Lonely Lentil® · Scooping since 2019</h1>
          </div>

        </div>
        <div className="container is-hidden-tablet ">
        <h1
          className=" tile-title "
          style={{
              color: "black"
          }}>Lonely Lentil® · Scooping since 2019</h1>
        </div>
      </footer>
      </>
    )
  }
}



export default Footer
