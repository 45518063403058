import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
// import banner from "../img/xmas-banner.svg";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import "./all.sass";

const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data) => (
      <>
        <div
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <Helmet titleTemplate="Lonely Lentil">
            <html lang="en" />
            <title>Whole Foods | Plastic Free | Minimal Waste</title>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <link
              rel="stylesheet"
              href="https://cdn.jsdelivr.net/npm/bulma@0.9.1/css/bulma.min.css"
            ></link>
            <script
              src="https://kit.fontawesome.com/474bd97b9d.js"
              crossorigin="anonymous"
            ></script>
            <meta
              name="description"
              content={data.site.siteMetadata.description}
            />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
              href="https://fonts.googleapis.com/css2?family=Bangers&display=swap"
              rel="stylesheet"
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/img/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              href="/img/favicon-32x32.png"
              sizes="32x32"
            />
            <link
              rel="icon"
              type="image/png"
              href="/img/favicon-16x16.png"
              sizes="16x16"
            />

            <link
              rel="mask-icon"
              href="/img/safari-pinned-tab.svg"
              color="#ff4400"
            />

            <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
            <meta name="theme-color" content="#fff" />

            <meta property="og:type" content="business.business" />
            <meta property="og:title" content={data.site.siteMetadata.title} />
            <meta property="og:url" content="/" />
            <meta property="og:image" content="/img/og-image.jpg" />
          </Helmet>
          {/* <img src={banner} className="is-hidden-touch" /> */}
          <Navbar />
          <div>{children}</div>
          <Footer />
        </div>
      </>
    )}
  />
);

export default TemplateWrapper;
